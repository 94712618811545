import React from "react";
import Header from "../components/header";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import SiteConfig from "../../config/SiteConfig.json";
import CategoryTabs from "../components/BM-categotyTabs";

export default class CategoryTamplate extends React.Component {
  render() {
    let {
      category,
      lang,
      json,
      tutorialListItem,
      deviceTipsListItem
    } = this.props.pageContext;

    return (
      <Layout>
        <Seo
          postTitle={`${category} - ${SiteConfig.seo.siteName}`}
          postURL={this.props.location.pathname}
          postLanguage={lang}
        />
        <Header lang={lang} json={json} activeTab={category} />
        <CategoryTabs
          json={json}
          lang={lang}
          category={category}
          showPosts={true}
          tutorialListItem={tutorialListItem}
          deviceTipsListItem={deviceTipsListItem}
        />
      </Layout>
    );
  }
}
