export const CategoryIcons = {
  "Features Amp Usability": "/assets/Features&Usability.svg",
  "Managing Accounts": "/assets/Accounts.svg",
  "General Help": "/assets/General.svg",
  "Notification Settings": "/assets/Settings.svg",
  "Getting Started": "/assets/GettingStarted.svg",
  "Troubleshooting": "/assets/Troubleshooting.svg"
};

export const CategoryOrder = [
  "Getting Started",
  "General Help",
  "Features Amp Usability",
  "Managing Accounts",
  "Notification Settings",
  "Troubleshooting"
];

export const popularTips = [
  "/unified-inbox/",
  "/share-email/",
  "/calendar/",
  "/what-are-clusters/",
  "/lets-get-tasks-done/",
  "/conversations-support/",
  "/custom-signatures/",
  "/rich-text-compose/",
  "/check-push-status/"
];
